import { flatten, isNumber } from "lodash";

import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import { IShopifyLiquidPriceAdjustment } from "@smartrr/shared/shopifyLiquid/selling_plan_group";
import { localize, typedLocalize } from "@smartrr/shared/utils/sharedTranslations/localize";

import { BigIntString } from "../../entities/BigIntString";
import { IPurchaseState, isUpcomingDelivery } from "../../entities/PurchaseState";
import {
  IPricingPolicyCycleAdjustment,
  IPurchaseStateLineItem,
} from "../../entities/PurchaseState/CustomerPurchaseLineItem";
import {
  DiscountTargetType,
  DiscountValueType,
  ILineItemDiscount,
  IPurchaseStateLineItemDiscount,
} from "../../interfaces/Discount";
import { CurrencyCode, SellingPlanPricingPolicyAdjustmentType } from "../../shopifyGraphQL/api";
import { BundleLineItemTypes } from "../bundles";
import { formatMoney, removeTrailingZeros, unformatMoney } from "../formatMoney";
import { isActiveDiscount, selectPricingPolicyComputedPriceForCycle } from "../getDiscounts";
import { isDefaultVariant } from "../isDefaultVariant";
import { IPurchasable } from "../../entities/Purchasable";
import { ProductApi } from "../../interfaces/product/api";
import { VariantApi } from "../../interfaces/variant/api";
import { BadgeStatusValue } from "@shopify/polaris";
import { isCPSPrepaid } from "../isPrepaid";

export function getSubtotalDisplay(
  paymentMultipleDueOnDate: number,
  customerPurchaseState: IPurchaseState,
  skdIdx: number,
  includeAddons = true
) {
  // N.b.: paymentMultipleDueOnDate is factored into computedPrice but not basePrice
  const { currency } = customerPurchaseState;
  const orderNumber = customerPurchaseState.schedule.totalOrdersCount || skdIdx;
  const totalFromPricingPolicy = customerPurchaseState.stLineItems.reduce((acc, lineItem) => {
    if (!includeAddons && lineItem.isAddOn) {
      return acc;
    }
    let pricingPolicyComputedPrice = lineItem.pricingPolicy
      ? selectPricingPolicyComputedPriceForCycle(lineItem.pricingPolicy, orderNumber)
      : // if we cannot use computed price, use basePrice, but we therefore need to factor in paymentMultipleDueOnDate
        paymentMultipleDueOnDate * lineItem.basePrice;

    if (
      lineItem.pricingPolicy?.cycleDiscounts[0].adjustmentType === SellingPlanPricingPolicyAdjustmentType.Price
    ) {
      pricingPolicyComputedPrice = pricingPolicyComputedPrice * paymentMultipleDueOnDate;
    }

    return !Number.isFinite(lineItem.skdIdx) || lineItem.skdIdx === +skdIdx
      ? acc + +pricingPolicyComputedPrice * lineItem.quantity
      : acc;
  }, 0);

  // we do NOT want paymentMultipleDueOnDate here because totalFromPricingPolicy already
  // has paymentMultipleDueOnDate factored in, either via computedPrice or basePrice * paymentMultipleDueOnDate
  return formatMoney(BigIntString.fromBigIntString(totalFromPricingPolicy), currency);
}

export function getBasePriceSubTotalDisplay(
  paymentMultipleDueOnDate: number,
  customerPurchaseState: IPurchaseState,
  skdIdx: number
) {
  // N.b.: paymentMultipleDueOnDate is factored into computedPrice but not basePrice
  const { currency } = customerPurchaseState;
  const totalFromLineItemBasePrice = customerPurchaseState.stLineItems.reduce((acc, lineItem) => {
    return !Number.isFinite(lineItem.skdIdx) || lineItem.skdIdx === +skdIdx
      ? acc + +lineItem.basePrice * lineItem.quantity
      : acc;
  }, 0);

  //  we DO WANT paymentMultipleDueOnDate, here, because basePrice is by item, not by line
  return formatMoney(
    paymentMultipleDueOnDate * BigIntString.fromBigIntString(totalFromLineItemBasePrice),
    currency
  );
}

export function getShippingDisplay({
  totalShipping,
  totalShippingDiscount = BigIntString.toBigIntString(0),
  currency,
  schedule,
  subProperties,
}: IPurchaseState) {
  const shippingDifference = BigIntString.difference([totalShipping, totalShippingDiscount]);
  const isFreeShipping = BigIntString.equals(shippingDifference, 0);
  const isOrderCycleChanged =
    schedule.skdIdx! === 0 && schedule.orderCycleIndex === schedule.paymentFrequencyMultiple - 1;
  const isNextOrderDateChanged = !!subProperties?.nextOrderDateChanged;

  if (isFreeShipping) {
    return typedLocalize("orderHistory.freeShipping");
  }

  let adjustedShipping = shippingDifference;
  if (schedule.orderCycleIndex! > 0 && (!isOrderCycleChanged || isNextOrderDateChanged)) {
    adjustedShipping = BigIntString.product([
      shippingDifference,
      BigIntString.toBigIntString(schedule.paymentFrequencyMultiple),
    ]);
  }

  const formattedShipping = formatMoney(BigIntString.fromBigIntString(adjustedShipping), currency);
  return formattedShipping;
}

export function getTotalDisplay(
  paymentMultipleDueOnDate: number,
  customerPurchaseState: IPurchaseState,
  skdIdx: number,
  includeAddons = true,
  indexFromNextDelivery = 0,
  options?: {
    /** Relates specially to changes made for Jolie's Special referrals
     *
     *  variantIdsToOmit - an array of shopify variant gids that will be skipped when calculating total
     *  if delivery meets additional set of conditions
     */
    variantIdsToOmit?: string[];
    evaluatePriceAdjustmentType?: boolean;
  }
) {
  // N.b.: paymentMultipleDueOnDate is factored into computedPrice but not basePrice
  const { currency, subProperties } = customerPurchaseState;
  const remainingSpecialRewards = subProperties?.remainingSpecialRewards;

  const orderNumber = customerPurchaseState.schedule.totalOrdersCount || skdIdx;
  const isUpcoming = isUpcomingDelivery(indexFromNextDelivery);
  const totalFromPricingPolicy =
    paymentMultipleDueOnDate === 0
      ? 0
      : customerPurchaseState.stLineItems.reduce((acc, lineItem) => {
          // we want NEXT cycle computed prices
          const isBundleChild = lineItem.bundleType === BundleLineItemTypes.BUNDLE_LINE;
          const containsOmittedVariant = options?.variantIdsToOmit
            ? options.variantIdsToOmit?.some(vntId => lineItem.vnt?.shopifyId === vntId)
            : false;
          const isRemainingSpecialRewardsValid =
            isNumber(remainingSpecialRewards) &&
            remainingSpecialRewards > 0 &&
            indexFromNextDelivery <= remainingSpecialRewards;

          if (isBundleChild) {
            return acc;
          }
          if (!includeAddons && lineItem.isAddOn) {
            return acc;
          }

          // Special Referrals
          if (!isUpcoming && isRemainingSpecialRewardsValid && containsOmittedVariant) {
            return acc;
          }

          let pricingPolicyComputedPrice = lineItem.pricingPolicy
            ? selectPricingPolicyComputedPriceForCycle(
                lineItem.pricingPolicy,
                orderNumber + indexFromNextDelivery
              )
            : paymentMultipleDueOnDate * lineItem.basePrice;

          if (
            options?.evaluatePriceAdjustmentType &&
            lineItem.pricingPolicy?.cycleDiscounts[0].adjustmentType ===
              SellingPlanPricingPolicyAdjustmentType.Price
          ) {
            pricingPolicyComputedPrice = pricingPolicyComputedPrice * paymentMultipleDueOnDate;
          }

          return !Number.isFinite(lineItem.skdIdx) || lineItem.skdIdx === +skdIdx
            ? acc + +pricingPolicyComputedPrice * lineItem.quantity
            : acc;
        }, 0);

  const totalShippingDiscount =
    customerPurchaseState.totalShippingDiscount && customerPurchaseState.schedule.paymentFrequencyMultiple === 1
      ? customerPurchaseState.totalShippingDiscount
      : 0;

  let shipping = BigIntString.equals(
    BigIntString.difference([customerPurchaseState.totalShipping, totalShippingDiscount]),
    0
  )
    ? 0
    : BigIntString.fromBigIntString(
        BigIntString.difference([customerPurchaseState.totalShipping, totalShippingDiscount])
      );

  const isOrderCycleChanged =
    customerPurchaseState.schedule.skdIdx! === 0 &&
    customerPurchaseState.schedule.orderCycleIndex ===
      customerPurchaseState.schedule.paymentFrequencyMultiple - 1;
  const isNextOrderDateChanged = !!customerPurchaseState.subProperties?.nextOrderDateChanged;

  if (
    isCPSPrepaid(customerPurchaseState.schedule) &&
    customerPurchaseState.schedule.orderCycleIndex! > 0 &&
    (!isOrderCycleChanged || isNextOrderDateChanged)
  ) {
    shipping = shipping * paymentMultipleDueOnDate;
  }

  const totalPrice = Math.max(
    0,
    BigIntString.fromBigIntString(totalFromPricingPolicy) +
      shipping -
      getTotalDiscounts(customerPurchaseState, orderNumber, paymentMultipleDueOnDate, indexFromNextDelivery)
  );

  // we do NOT want paymentMultipleDueOnDate here because totalFromPricingPolicy already
  // has paymentMultipleDueOnDate factored in, either via computedPrice or basePrice * paymentMultipleDueOnDate
  return formatMoney(totalPrice, currency);
}

export function getTotalDiscountsFormatted(discountSum: number, currency: `${CurrencyCode}`) {
  return formatMoney(BigIntString.fromBigIntString(discountSum), currency);
}

export function getTotalDiscounts(
  customerPurchaseState: IPurchaseState,
  orderNumber: number,
  paymentMultipleDueOnDate: number,
  indexFromNextDelivery: number
): number {
  const lineItemsDiscountAmount = getLineItemsDiscountAmount(
    customerPurchaseState,
    orderNumber,
    paymentMultipleDueOnDate,
    indexFromNextDelivery
  );
  const subscriptionDiscountsAmount = getSubscriptionDiscountsAmount(
    customerPurchaseState,
    getLineItemsPrice(customerPurchaseState, orderNumber, paymentMultipleDueOnDate),
    indexFromNextDelivery
  );

  return lineItemsDiscountAmount + subscriptionDiscountsAmount;
}

export function getLineItemsDiscountAmount(
  customerPurchaseState: IPurchaseState,
  orderNumber: number,
  paymentMultipleDueOnDate: number,
  indexFromNextDelivery: number
): number {
  const lineItemsDiscounts = flatten(
    customerPurchaseState.stLineItems.map(line => line.discounts.map(d => ({ discount: d, lineItem: line })))
  );

  return lineItemsDiscounts.reduce((acc, { discount, lineItem }) => {
    // No longer checking for discount.code being present as some internally applied discounts omit code
    const isDiscountApplicable = isActiveDiscount(discount);
    const pricingPolicyComputedPrice = getLineItemComputedPrice(lineItem, orderNumber, paymentMultipleDueOnDate);
    const isDiscountApplicableToCurrentOrder =
      discount.recurringCycleLimit == null
        ? true
        : discount.recurringCycleLimit >= discount.usageCount + (indexFromNextDelivery + 1);

    if (!isDiscountApplicable || discount.orderWideDiscount || !isDiscountApplicableToCurrentOrder) {
      return acc;
    }

    return acc + getLineItemDiscountAmount(discount, lineItem.quantity, pricingPolicyComputedPrice);
  }, 0);
}

export function getSubscriptionDiscountsAmount(
  customerPurchaseState: IPurchaseState,
  priceForLineItems: number,
  indexFromNextDelivery: number
): number {
  return customerPurchaseState.discounts.reduce((acc, discount) => {
    const isDiscountApplicableToCurrentOrder =
      discount.recurringCycleLimit == null
        ? true
        : discount.recurringCycleLimit >= discount.usageCount + (indexFromNextDelivery + 1);

    if (
      !isDiscountApplicableToCurrentOrder ||
      (discount.targetType == null ? false : discount.targetType !== DiscountTargetType.LINE_ITEM) ||
      !discount.orderWideDiscount
    ) {
      return acc;
    }

    return acc + getLineItemDiscountAmount(discount, 1, priceForLineItems);
  }, 0);
}

/**
 * Formats amount of discount code applied to line item.
 * WARNING: this is a very unreliable method because discounts in Shopify are driven by complex pricing rules
 * and we can't accurately predict discount amount for upcoming orders.
 */
export function formatDiscountValue(discount: ILineItemDiscount) {
  if (discount.targetType === DiscountTargetType.SHIPPING_LINE && discount.value) {
    return localize("orderHistory.freeShipping");
  }
  return discount.valueType === DiscountValueType.FIXED
    ? `(${formatMoney(discount.value, discount.currency!)})`
    : `(${discount.value}%)`;
}

/**
 * Formats price adjustment defined by a subscription pricing policy on certain billing cycle.
 * In other words, shows how much customer saves on a single item because of subscription.
 */
export function formatAdjustmentValue(adjustment: IPricingPolicyCycleAdjustment, currency: CurrencyCode) {
  switch (adjustment.adjustmentType) {
    case SellingPlanPricingPolicyAdjustmentType.FixedAmount: {
      return `${formatMoney(unformatMoney(adjustment.adjustmentValue.fixedValue, currency), currency)}`;
    }

    case SellingPlanPricingPolicyAdjustmentType.Price: {
      return `${formatMoney(unformatMoney(adjustment.adjustmentValue.fixedValue, currency), currency)}`;
    }

    case SellingPlanPricingPolicyAdjustmentType.Percentage: {
      return `${adjustment.adjustmentValue.percentage}%`;
    }

    default: {
      const exhaustiveCheck: never = adjustment;
      return exhaustiveCheck;
    }
  }
}

export function formatAdjustmentValueOnItemPrice(
  adjustment: IPricingPolicyCycleAdjustment,
  currency: CurrencyCode,
  vnt: IPurchasableVariant
) {
  const variantPriceInCurrency: number = vnt.presentmentPrices.find(
    presentmentPrice => presentmentPrice.priceCurrency === currency
  )?.price;

  const handlePriceInput = (inputPrice: number, currency: CurrencyCode) => {
    const price = Math.max(0, inputPrice);
    return removeTrailingZeros(formatMoney(unformatMoney(price, currency), currency));
  };

  switch (adjustment.adjustmentType) {
    case SellingPlanPricingPolicyAdjustmentType.Price: {
      return handlePriceInput(adjustment.adjustmentValue.fixedValue * 100, currency);
    }
    case SellingPlanPricingPolicyAdjustmentType.FixedAmount: {
      return handlePriceInput(variantPriceInCurrency - adjustment.adjustmentValue.fixedValue * 100, currency);
    }
    case SellingPlanPricingPolicyAdjustmentType.Percentage: {
      return handlePriceInput(
        variantPriceInCurrency * ((100 - adjustment.adjustmentValue.percentage) / 100),
        currency
      );
    }

    default: {
      const exhaustiveCheck: never = adjustment;
      return exhaustiveCheck;
    }
  }
}

export function formatAdjustmentValuePrice(
  adjustment: IShopifyLiquidPriceAdjustment,
  currency: CurrencyCode,
  variant: IPurchasableVariant
) {
  enum SellingPlanPricingPolicyAdjustmentType {
    Percentage = "percentage",
    FixedAmount = "fixed_amount",
    Price = "price",
  }

  const variantPriceInCurrency: number = variant.presentmentPrices.find(
    presentmentPrice => presentmentPrice.priceCurrency === currency
  )?.price;

  const handlePriceInput = (inputPrice: number, currency: CurrencyCode) => {
    const price = Math.max(0, inputPrice);
    return removeTrailingZeros(formatMoney(unformatMoney(price, currency), currency));
  };

  switch (adjustment?.value_type) {
    case SellingPlanPricingPolicyAdjustmentType.Price: {
      return handlePriceInput(adjustment.value * 100, currency);
    }
    case SellingPlanPricingPolicyAdjustmentType.FixedAmount: {
      return handlePriceInput(variantPriceInCurrency - adjustment.value * 100, currency);
    }
    case SellingPlanPricingPolicyAdjustmentType.Percentage: {
      return handlePriceInput(variantPriceInCurrency * ((100 - adjustment.value) / 100), currency);
    }
    default: {
      return adjustment;
    }
  }
}

export function pricingPolicyHasDiscounts(adjustment: IPricingPolicyCycleAdjustment): boolean {
  switch (adjustment.adjustmentType) {
    case SellingPlanPricingPolicyAdjustmentType.FixedAmount: {
      return adjustment.adjustmentValue.fixedValue > 0;
    }

    case SellingPlanPricingPolicyAdjustmentType.Price: {
      return adjustment.adjustmentValue.fixedValue > 0;
    }

    case SellingPlanPricingPolicyAdjustmentType.Percentage: {
      return adjustment.adjustmentValue.percentage > 0;
    }

    default: {
      const exhaustiveCheck: never = adjustment;
      return exhaustiveCheck;
    }
  }
}

// TODO: Clean up lower two functions
export function getSubtotalDisplayBundle(
  paymentMultipleDueOnDate: number,
  customerPurchaseState: IPurchaseState,
  skdIdx: number
) {
  // N.b.: paymentMultipleDueOnDate is factored into computedPrice but not basePrice
  const { currency } = customerPurchaseState;
  const orderNumber = customerPurchaseState.schedule.totalOrdersCount || skdIdx;
  const totalFromPricingPolicy = customerPurchaseState.stLineItems.reduce((acc, lineItem) => {
    if (lineItem.bundleType === BundleLineItemTypes.BUNDLE_LINE) {
      return acc;
    }
    const pricingPolicyComputedPrice = lineItem.pricingPolicy
      ? selectPricingPolicyComputedPriceForCycle(lineItem.pricingPolicy, orderNumber)
      : // if we cannot use computed price, use basePrice, but we therefore need to factor in paymentMultipleDueOnDate
        paymentMultipleDueOnDate * lineItem.basePrice;
    return !Number.isFinite(lineItem.skdIdx) || lineItem.skdIdx === +skdIdx
      ? acc + +pricingPolicyComputedPrice * lineItem.quantity
      : acc;
  }, 0);

  // we do NOT want paymentMultipleDueOnDate here because totalFromPricingPolicy already
  // has paymentMultipleDueOnDate factored in, either via computedPrice or basePrice * paymentMultipleDueOnDate
  return formatMoney(BigIntString.fromBigIntString(totalFromPricingPolicy), currency);
}

function getLineItemComputedPrice(
  line: IPurchaseStateLineItem,
  orderNumber: number,
  paymentMultipleDueOnDate: number
): number {
  return line.pricingPolicy
    ? selectPricingPolicyComputedPriceForCycle(line.pricingPolicy, orderNumber)
    : paymentMultipleDueOnDate * line.basePrice;
}

function getLineItemDiscountAmount(
  discount: IPurchaseStateLineItemDiscount,
  quantity: number,
  pricingPolicyComputedPrice: number
): number {
  if (discount.valueType == DiscountValueType.PERCENTAGE) {
    return (discount.value / 100) * (pricingPolicyComputedPrice * quantity);
  }
  return Math.min(discount.value * quantity, pricingPolicyComputedPrice * quantity);
}

function getLineItemsPrice(
  customerPurchaseState: IPurchaseState,
  orderNumber: number,
  paymentMultipleDueOnDate: number
): number {
  return customerPurchaseState.stLineItems.reduce((acc, lineItem) => {
    const isLineItem = lineItem.skdIdx == null;
    const isApplicableAddon = lineItem.skdIdx === orderNumber;

    if (!isLineItem && !isApplicableAddon) {
      return acc;
    }

    return acc + getLineItemComputedPrice(lineItem, orderNumber, paymentMultipleDueOnDate) * lineItem.quantity;
  }, 0);
}

export function formatProductAndVariantNameForDisplay({
  purchasableName,
  purchasableVariantName,
  showVariant = true,
  isActive = true,
  sku,
}: {
  purchasableName: string;
  purchasableVariantName: string | undefined;
  showVariant?: boolean;
  isActive?: boolean;
  sku?: string;
}) {
  let str = purchasableName;
  if (showVariant && purchasableVariantName && !isDefaultVariant(purchasableVariantName)) {
    str += ` - ${purchasableVariantName}`;
  }
  if (!isActive) {
    str += " (Deleted)";
  }
  if (sku) {
    str += ` ${sku}`;
  }
  return str;
}

export const renderProductStatusTitle = (
  obj: IPurchasable | IPurchasableVariant | ProductApi.Product | VariantApi.Variant | undefined
) => {
  if (!obj) {
    return "";
  }
  if (!obj.isActiveInShopify) {
    return "Deleted";
  }
  if (obj.isDraftOrArchived) {
    return "Draft / Archived";
  }
  if (obj.isActiveInShopify) {
    return "Active";
  }
};

export const renderProductStatus = (
  obj: IPurchasable | IPurchasableVariant | ProductApi.Product | VariantApi.Variant | undefined
): BadgeStatusValue | undefined => {
  if (!obj) {
    return undefined;
  }
  if (!obj.isActiveInShopify) {
    return BadgeStatusValue.Critical;
  }
  if (obj.isDraftOrArchived) {
    return BadgeStatusValue.New;
  }
  if (obj.isActiveInShopify) {
    return BadgeStatusValue.Success;
  }
};
